import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const loaders = {
  loaders: {
    loader:
      "<div style='height: 100vh;color: #0364B6;display: flex;justify-content: center;align-items: center;font-size: 6rem;'><i class='fak fa-joobpay fa-flip' style='--fa-animation-duration: 2s;'></i></div>",
  },
};

function cambiarIcono(mediaQuery) {
  const favicon = document.querySelector("link[rel='shortcut icon']");

  if (mediaQuery.matches) {
    favicon.href = "/favicon-black.png";
  } else {
    favicon.href = "/favicon-blue.png";
  }
}

// Utiliza el Media Query para detectar el modo oscuro
const prefersDarkMode = window.matchMedia("(prefers-color-scheme: dark)");
prefersDarkMode.addEventListener("change", cambiarIcono);

// Llama a la función para configurar el ícono inicialmente
cambiarIcono(prefersDarkMode);

const routes = constructRoutes(microfrontendLayout, loaders);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
